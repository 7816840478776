/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@100..900&display=swap');

@import 'material-symbols';

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";

@import "~@angular/cdk/overlay-prebuilt.css";

@import "assets/themes/index.scss";

.p-avatar-circle {
  border-radius: 100%;
}

// espera os ícones carregarem - app.component.ts
.wf-loading body {
  display: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Overpass, sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-background-clip: #ffffff;
  -webkit-text-fill-color: var(--grayscale-500);
}

@media print {
  body {
    counter-reset: page;
    background-color: transparent !important;
  }
}

body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: var(--grayscale-900);
  background-color: var(--grayscale-50);
}

.grecaptcha-badge {
  visibility: hidden;
}

.required-field {
  color: var(--system-danger-600);
}

.material-symbols-outlined {
  font-weight: 600;
}